import { Component, OnInit, Input, EventEmitter, ViewChild, HostBinding, OnChanges, ElementRef, NgZone } from '@angular/core';

import { ContentModel } from '../../content-model';
import { ContentEntryModel } from '../../content-entry-model';
import { IonSlides } from '@ionic/angular';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import {animate, style, transition, trigger, state} from "@angular/animations";
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalService } from "../../services/global.service";

@Component({
  selector: 'app-detail-view-component',
  templateUrl: './detail-view-component.component.html',
  styleUrls: ['./detail-view-component.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('default', style({
        height: 'calc(100vh - 44px - 50px)' 
      })),
      state('in', style({
        height: '78vh' //70vh
      })),
      state('out', style({
        height: 'calc(100vh - 44px - 50px)' //80vh + 50px
      })),
      transition('in => out', animate('250ms ease-in')), //ease-in
      transition('out => in', animate('250ms ease-out')) 
    ]),
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *', animate('200ms 300ms')),
      // transition('* => void', animate('150ms')),
    ]), 
  ]
})
export class DetailViewComponentComponent implements OnInit {

  //angular animation
  // @Input()
  // trigger: any;

  // trigger:any;

  // startHeight: number;

  // @ViewChild('image') element: ElementRef;

  // @HostBinding('@grow') get grow() {
  //   return {value: this.trigger, params: {startHeight: this.startHeight}};
  // }

  // setStartHeight(){
  //   setTimeout(() => {
  //     this.startHeight = this.element.nativeElement.clientHeight;
  //     console.log("startHeight:" + this.startHeight);
  //   }, 1000);
    
  // }

  // ngOnChanges(){
  //   console.log("ngOnChanges triggered");
  //   this.setStartHeight();
  // }
  // /. angular animation

  @Input() scrollArea:any;

  @Input() content: ContentModel;

  @Input() hiddenByScroll: boolean;

  // @Input() currentScrollTopValue: number;
  @Input() currentScrollStatusClass: string;

  @ViewChild('slideWithNav') slideWithNav: IonSlides;

  protected currentContentEntry: ContentEntryModel;

  public detailViewDisabled:boolean;

  //public showById:number;

  //public hiddenByScroll:boolean = false; // Return true if user has scrolled down

  //@Output() onNameSelected: EventEmitter;

  constructor(private streamingMedia: StreamingMedia, private zone: NgZone,private route: ActivatedRoute, private router: Router, private globalService: GlobalService) {
    // console.log("RUN constructor");
    // this.route.queryParams.subscribe(params => {

    //   const currentNavigation = this.router.getCurrentNavigation();
    //   console.log("Navigation query params:", params);
    //   if (currentNavigation && currentNavigation.extras.state) {
    //     this.showById = this.router.getCurrentNavigation().extras.state.id;
    //     console.log("SLIDER NOT LOADED. WAIT FOR LOAD AND THEN SHOW ID", this.showById);
    //   }
      // if(this.showById && this.sliderLoaded){
      //   this.content.viewType = 'detail';
      //   console.log("SLIDER LOADED: SHOW ID:", this.showById);
      //   this.slideToEntryById();
      // }
    //});
   }

  ngOnInit() {
    console.log("NG ON INIT");
  }

  // hiddenByScrollEvent(ev:any){
  //   this.hiddenByScroll = ev;
  // }

  sliderLoaded:boolean = false;
  
  ionSlidesDidLoad(slideView) {
    console.log("SLIDE DID LOAD!", slideView);
    console.log("SHO BY ID:", this.globalService.showById);

    this.sliderLoaded = true;

    if(this.globalService.showById){
      this.slideToEntryById();
      return;
    }

    if(!this.content.currentContentEntry && this.content.contentEntries.length > 0){

      this.content.currentContentEntry = this.content.contentEntries[0];
      this.checkForDetailView();

    }else{ 
        this.slideToCurrentContentEntry(); 
    }


  }

  // async ngAfterViewInit() {
  //   //console.log("ION VIEW WILL ENTER");
  //   //this.ionSlidesDidLoad();
  // }

  imageLoadingComplete( entry: ContentEntryModel){
    // this.content.contentEntries[
    //   this.content.contentEntries.indexOf(this.content.contentEntries.find(e => e.id === entry.id))
    // ].imageLoaded = true;
    
    this.zone.run(() => { // <== added
      entry.imageLoaded=true;
      console.log("Image loading complete", entry, event);
  });
   


  }


  //Move to Next slide
  slideNext(object, slideView) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });

  } 
 
  //Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }
 
  //Method called when slide is changed by drag or navigation
  SlideDidChange(contentEntry, slideView) {
    console.log("SLIDE DID CHANGE to content entry", contentEntry);
    this.checkIfNavDisabled(contentEntry, slideView);
    this.changeCurrentContentEntry();
  }
 
  slideToCurrentContentEntry(){

    if(this.content.contentEntries.length == 0 ) {
      return;
    }

    const index = this.content.contentEntries.findIndex((contentEntry) => {
      return contentEntry.id == this.content.currentContentEntry.id;
    });

    this.slideWithNav.slideTo(index, 100, true).then(() => this.checkForDetailView());
    
  }

  slideToEntryById(){
    console.log("slideToEntryById()");
    if(this.content.contentEntries.length == 0 ) {
      return;
    }

    const index = this.content.contentEntries.findIndex((contentEntry) => {
      return contentEntry.id === this.globalService.showById;
    });

    this.slideWithNav.slideTo(index, 100, true).then(() => this.changeCurrentContentEntry());
    console.log("slideToEntryById() SLIDE TO INDEX "+ index);
    // debugger;
    
  }

  changeCurrentContentEntry(){
    this.slideWithNav.getActiveIndex().then((index) => {
      console.log("CURRENT CONTENT ENTRY INDEX:"+ index);
      let currentContentEntry = this.content.contentEntries[index];
      this.content.currentContentEntry = currentContentEntry;
      this.checkForDetailView();
    });

    // this.content.currentContentEntry = contentEntry;
    // debugger;
  }

  // Disable Detail View if content entry has no description
  protected checkForDetailView() {
    this.detailViewDisabled = (this.content.currentContentEntry.description === '') ? true : false; 
  }

  playVideo(){
    let options: StreamingVideoOptions = {
      successCallback: () => { console.log('Video played') },
      errorCallback: (e) => { console.log('Error streaming') },
      //orientation: 'landscape',
      shouldAutoClose: true,
      controls: true
    };

    this.streamingMedia.playVideo('https://wbs.amzoom.de/gf.mp4', options);
  }

  //Call methods to check if slide is first or last to enable disbale navigation  
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }
 
  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  

}
