import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { Platform, MenuController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { LoadingService } from './loading.service';

const JWT_TOKEN = 'jwt-token';
const FULL_NAME = 'full_name';
const USERNAME = 'username';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // apiUrl = 'https://mylukas-preview03.netgroup.de/employee_api/login/employee'; // PROD
  // apiUrl = 'https://quick-start.preview03.netgroup.de/employee_api'; // PROD
  apiUrl = 'https://www.65549.ng-app.de/employee_api'; // PROD

  authenticationState = new BehaviorSubject(false);

  constructor(private http: HttpClient,private storage: Storage, private plt: Platform, public menu: MenuController, private alertController: AlertController, private loading: LoadingService) { 
    this.plt.ready().then(() => {
        this.checkToken();
      });
  }

  checkToken() {
    this.storage.get(JWT_TOKEN).then(res => {
      if (res) {
        console.log("FOUND TOKEN IN checkToken()!!", res);
        this.authenticationState.next(true);
        //this.toggleMenu();
      }
    })
  }

  toggleMenu():void{
    if(this.authenticationState.value){
      this.menu.enable(true);
    }else{
      this.menu.enable(false);
    }
  }

  isAuthenticated() {
    let isAuthenticated = this.authenticationState.value;
    this.toggleMenu();
    return this.authenticationState.value;
  }

  storeToken(token) {
    console.log("storeToken in auth service: ", token); 
    return this.storage.set(JWT_TOKEN, 'Bearer ' + token).then(() => {
      this.authenticationState.next(true);
    });
  }

  storeUsername(username) {
    console.log("storeUsername in auth service: ", username); 
    return this.storage.set(USERNAME, username).then(() => {
    });
  }

  storeFullName(full_name) {
    console.log("store full name in auth service: ", full_name); 
    return this.storage.set(FULL_NAME, full_name).then(() => {
    });
  }

  login (data): Observable<any> {
    console.log("Login data:", data);

    data.username = data.username.toString().trim();
    data.password = data.password.toString().trim();

    this.loading.present();
    return this.http.post<any>(this.apiUrl + '/login/employee' + '?username='+data.username + '&password='+data.password, null)
      .pipe(
        tap(_ => this.loading.dismiss()),
        catchError(this.handleError('api-login', [])) 
      );
  }

  logout() {
    return this.storage.remove(JWT_TOKEN).then(() => {
      return this.storage.remove(FULL_NAME).then(() => {
        this.authenticationState.next(false);
      });
    });

    
  }

  // Register
  register (data): Observable<any> {

    let formData = 'username='+data.username + '&password='+data.password + '&firstname='+data.firstname + '&lastname='+data.lastname;
    if(data.email){
      formData = formData + '&email='+data.email;
    } 

    return this.http.post<any>(this.apiUrl + '/member/register', formData) // data 
      .pipe(
        catchError(this.handleError('register', []))
      );
  }
  // register (data): Observable<any> {

    

  //   return this.http.post<any>(this.apiUrl + '/member/register' + '?username='+data.username + '&password='+data.password + '&firstname='+data.firstname + '&lastname='+data.lastname + '&email='+data.email, null) // data 
  //     .pipe(
  //       catchError(this.handleError('register', []))
  //     );
  // }

    // Reset Password by E-Mail
    resetByEmail (data): Observable<any> {
      let formData = 'username='+data.username + '&email='+data.email;
      return this.http.post<any>(this.apiUrl + '/member/reset/password-email', formData)
        .pipe(
          catchError(this.handleError('reset-password-by-email', []))
        );
    }

  // Reset Password by QR Code
  reset (data): Observable<any> {
    let formData = 'username='+data.username + '&token='+data.token + '&password='+data.password;
    return this.http.post<any>(this.apiUrl + '/member/reset/password-code', formData)
      .pipe(
        catchError(this.handleError('reset-password-by-qr-code', []))
      );
  }

  // Activate
  activate (data): Observable<any> {
    let formData = 'username='+data.username + '&token='+data.token;
    return this.http.post<any>(this.apiUrl + '/member/activate', formData)
      .pipe(
        catchError(this.handleError('activate', []))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loading.dismiss();
      console.log(`${operation} failed. Message: ${error}`);
      if(error.error && error.error.message){this.showErrorMessage(error.error.message);}
      return of(result as T);
    };
  }

  async showErrorMessage(message:string){
    const alert = await this.alertController.create({
      header: 'Fehler:',
      message: message,
      buttons: [{text: 'OK', cssClass: 'input-btn-dark'}]
    });
    await alert.present();   
  }

}