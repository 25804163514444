import { Directive, AfterViewInit, ElementRef, Renderer2, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
	selector: '[parallaxHeader]',
	host: {
		'(ionScroll)': 'onContentScroll($event)'
	}
})
export class ParallaxHeaderDirective implements AfterViewInit {

	@Input('parallaxHeader') imagePath: string;
	@Input('parallaxHeight') parallaxHeight: number;

	@Output()
	hiddenByScrollEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	private headerHeight: number;
	private header: HTMLDivElement;
	private mainContent: HTMLDivElement;

	//TEST
	@Input('imageOpacity') imageOpacity: any;

	constructor(private element: ElementRef, private renderer: Renderer2, private domCtrl: DomController) {

	}

	ngOnChanges(changes: SimpleChanges) {
		console.log("CHANGE DETECTED", changes);

		
		if (changes.imagePath.previousValue !== undefined) {
			//console.log("REAL CHANGE DETECTED. Set new Image:", changes.imagePath.currentValue, this.imagePath);

			//Replace image
			this.domCtrl.write(() => {

				console.log("Set Header Image on Change to", this.imagePath);
				this.renderer.setStyle(this.header, 'background-image', 'url(' + this.imagePath + ')');
				this.renderer.removeClass(this.header, 'fade-in-header');
				// this.renderer.addClass(this.header, 'fade-out-header');
				// 		this.header = this.renderer.createElement('div');
				this.domCtrl.write(() => {
					// this.renderer.removeClass(this.header, 'fade-out-header');
					this.renderer.addClass(this.header, 'fade-in-header');

				});

				// 		this.renderer.insertBefore(this.element.nativeElement, this.header, this.element.nativeElement.firstChild);

				// 		this.renderer.setStyle(this.header, 'background-image', 'url(' + this.imagePath + ')');
				// 		this.renderer.setStyle(this.header, 'height', this.headerHeight + 'px');
				//   this.renderer.setStyle(this.header, 'background-size', 'cover');
				//   this.renderer.setStyle(this.header, 'background-position', 'center');

			});



			//this.ngAfterViewInit();
		}


	}


	ngAfterViewInit() {



		//this.headerHeight = this.parallaxHeight;
		this.headerHeight = document.documentElement.clientHeight - 44 - 50; // -95
		this.parallaxHeight = this.headerHeight;

		this.mainContent = this.element.nativeElement.querySelector('.main-content');

		this.domCtrl.write(() => {

			this.header = this.renderer.createElement('div');
			this.renderer.addClass(this.header, 'header-image');
			this.renderer.addClass(this.header, 'fade-in-header');



			this.renderer.insertBefore(this.element.nativeElement, this.header, this.element.nativeElement.firstChild);


			if (this.imagePath !== null) {
				console.log("Set Header Image on Init to", this.imagePath);
				this.renderer.setStyle(this.header, 'background-image', 'url(' + this.imagePath + ')');
			}

			this.renderer.setStyle(this.header, 'height', this.headerHeight + 'px');
			this.renderer.setStyle(this.header, 'background-size', 'cover');
			this.renderer.setStyle(this.header, 'background-position', 'center');


		});

	}

	onContentScroll(ev) {

		// if(!this.imagePath){
		// 	return;
		// }

		this.domCtrl.read(() => {

			let translateAmt, scaleAmt;

			// Already scrolled past the point at which the header image is visible
			if (ev.detail.scrollTop > this.parallaxHeight) {
				return;
			}

			if (ev.detail.scrollTop > this.parallaxHeight * 0.03) {
				this.hiddenByScrollEvent.emit(true);
			} else {
				this.hiddenByScrollEvent.emit(false);
			}


			if (ev.detail.scrollTop >= 0) {
				translateAmt = -(ev.detail.scrollTop / 50);
				scaleAmt = 1;
			} else {
				translateAmt = 0;
				scaleAmt = -ev.detail.scrollTop / this.headerHeight + 1;
			}

			this.domCtrl.write(() => {

				// if(ev.detail.scrollTop >= 110){
				// 	this.renderer.setStyle(this.header, 'height', "calc(75vh - 44px - 50px)");
				// }else{
				// 	this.renderer.setStyle(this.header, 'height', this.headerHeight + 'px');
				// } 


				this.renderer.setStyle(this.header, 'transform', 'translate3d(0,' + translateAmt + 'px,0) scale(' + scaleAmt + ',' + scaleAmt + ')');
				this.renderer.setStyle(this.mainContent, 'transform', 'translate3d(0, ' + (-ev.detail.scrollTop * 0.3) + 'px, 0');
			});

		});

	}

}