import { ContentEntryModel } from './content-entry-model';
export class ContentModel {
    public viewType: string; //list or detail
    public mediaViewType: string; //gfblog, news, skills, or quiz
    public contentEntries: ContentEntryModel[];
    public currentContentEntry: ContentEntryModel;
    public isBeginningSlide: boolean = true;
    public isEndSlide:boolean = false;
    public categoryList: any[];
    public sliderOptions = {
        initialSlide: 0,
        slidesPerView: 1,
        preloadImages: false,
        lazyLoading: true,
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true
        // },
        lazy: {
          loadPrevNext: true
        },
        autoplay:false
      }
}