import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ContentModel } from '../../content-model';

@Component({
  selector: 'app-list-view-component',
  templateUrl: './list-view-component.component.html',
  styleUrls: ['./list-view-component.component.scss']
})
export class ListViewComponentComponent implements OnInit {

  @Input() content: ContentModel;
  
  @Output()
  contentEntrySelected : EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public captureName(event: any) : void
  {
     console.log(`Captured name by event value: ${event}`);
  }

  public getContentEntriesByCategory(category:string){
    let filtered = this.content.contentEntries.filter((entry) => entry.list_category == category);
    //console.log("FILTERED FOR CATEGORY", category, filtered);
    return filtered;
     

  }

  public captureContentEntrySelected(event:any) : void {
    this.contentEntrySelected.emit(event);
    //console.log(`Captured selected content entry by event value: ${event}`);
    //console.log(event);
  }

}
