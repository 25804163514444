import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimationsComponent } from './animations/animations.component';

//To use any components inside custom components
import { IonicModule } from '@ionic/angular';
import { ViewComponentComponent } from './view-component/view-component.component';
import { DetailViewComponentComponent } from './detail-view-component/detail-view-component.component';
import { ListViewComponentComponent } from './list-view-component/list-view-component.component';
import { ListViewAvatarComponentComponent } from './list-view-avatar-component/list-view-avatar-component.component';
import { ListViewQuizComponentComponent } from './list-view-quiz-component/list-view-quiz-component.component';
import { ListViewAccordionComponentComponent } from './list-view-accordion-component/list-view-accordion-component.component';
import { RegisterModalComponent } from './register-modal/register-modal.component';
import { ScanqrModalComponent } from './scanqr-modal/scanqr-modal.component';

import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

import { ScrollHideDirective } from '../directives/scroll-hide.directive';
import { ScrollVanishDirective } from '../directives/scroll-vanish.directive';
import { ParallaxHeaderDirective } from '../directives/parallax-header.directive';
import { PipesModule } from '../pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AnimationsComponent, ViewComponentComponent, DetailViewComponentComponent, ListViewComponentComponent, ListViewAvatarComponentComponent, ListViewQuizComponentComponent, ListViewAccordionComponentComponent, ScanqrModalComponent, RegisterModalComponent, ScrollHideDirective, ScrollVanishDirective, ParallaxHeaderDirective],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule
  ],
  entryComponents: [
    RegisterModalComponent,
    ScanqrModalComponent
],
  exports: [AnimationsComponent, ViewComponentComponent, DetailViewComponentComponent, ListViewComponentComponent, ListViewAvatarComponentComponent, ListViewQuizComponentComponent, ListViewAccordionComponentComponent, ScanqrModalComponent, RegisterModalComponent, ScrollHideDirective, ScrollVanishDirective, ParallaxHeaderDirective]
})
export class ComponentsModule { }
