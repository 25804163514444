import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ToastController, ModalController, AlertController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { ScanqrModalComponent } from '../scanqr-modal/scanqr-modal.component';
 
@Component({ 
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {

  loginForm: FormGroup;

  passwordClearText:boolean = false;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastController: ToastController,
    private iab: InAppBrowser,
    private modalController: ModalController,
    private alertController: AlertController) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      'username': ['', [Validators.required, Validators.minLength(6), 
        Validators.maxLength(8)]],
      'firstname': ['', [Validators.required]],
      'lastname': ['', [Validators.required]],
      'password': ['', [Validators.required]],
      'email': [''],
      'confirmPassword': ['']
    }, {'validator': this.checkPasswords });
  }

  closeModal() {
    this.modalController.dismiss();
  }
  closeModalWithData(data:any) {
    this.modalController.dismiss(data);
  }

  async showMessage(message:string){
    const alert = await this.alertController.create({
      // header: 'Fehler:',
      message: message,
      buttons: [{text: 'OK', cssClass: 'input-btn-dark'}]
    });
    await alert.present();   
  }

  async openScanqrModal(username:any) {

    this.modalController.create({
      component: ScanqrModalComponent,
        // mode: "ios",
        componentProps: {
          username: username
       }

      }).then((modal) => {
        modal.onDidDismiss().then((res) => {
          if (res !== null) {
            console.log('The result:', res);
          }else{
            console.log("Closed modal without result");
          }
      });

      modal.present();
      });
}

checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('password').value;
  let confirmPass = group.get('confirmPassword').value;

  return pass === confirmPass ? null : { notSame: true }     
}


  onFormSubmit(form: NgForm) {
    this.authService.register(form)
      .subscribe(res => {
        console.log("RESPONSE FROM REGISTRATION:", res);
       
        //FAKE LOGIN MOCK FOR DEBUG
        // this.authService.storeToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InByZXZpZXciLCJpYXQiOjE1Njc4NjQxNTcsImV4cCI6MTU2Nzk1MDU1N30.XjYVn1IL8o4VrFJfuECO5oZ8TvOMtMiMsBlZCCmWrig'); // TESTTOKEN
        // this.loginForm.reset();
        // this.authService.toggleMenu();
        // return;
        // /. FAKE LOGIN MOCK FOR DEBUG

        if (res && res.success) {
          console.log("REGISTERED SUCESSFULLY", res);
          this.loginForm.reset();
          // this.showMessage('Registrierung erfolgreich! Ihr Benutzername ist:' + res.member);

          // Add username to local storage 
          this.authService.storeUsername(res.member);

          this.closeModalWithData({registered: true});
          // this.closeModal();
          //this.openScanqrModal();
          // this.authService.storeToken(res.token);
        }


      });
  }

  
  toggleClearTextPassword() {
    this.passwordClearText = (this.passwordClearText) ? false : true;
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  openExternalBrowser(url:any){
    let browser = this.iab.create(url, '_system');
  }

}

 