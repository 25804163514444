import { Component, ViewChild, ElementRef } from '@angular/core';
import { ToastController, LoadingController, Platform, ModalController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import jsQR from 'jsqr';
import { AuthService } from '../../services/auth.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-scanqr-modal',
  templateUrl: './scanqr-modal.component.html',
  styleUrls: ['./scanqr-modal.component.scss']
})
export class ScanqrModalComponent {
  // @ViewChild('video', { static: false }) video: ElementRef;
  // @ViewChild('canvas', { static: false }) canvas: ElementRef;
  // @ViewChild('fileinput', { static: false }) fileinput: ElementRef;
  @ViewChild('video') video: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('fileinput') fileinput: ElementRef;

  loginForm: FormGroup;
 
  canvasElement: any;
  videoElement: any;
  canvasContext: any;
  scanActive = false;
  scanResult = null;
  loading: HTMLIonLoadingElement = null;

  username: string;

  isInStandaloneMode: boolean;

  scanType: string;
 
  constructor(
    private formBuilder: FormBuilder,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private plt: Platform,
    private modalController: ModalController,
    private authService: AuthService,
    private alertController: AlertController,
    private storage: Storage,
    private navParams: NavParams
  ) {

    if (this.plt.is('ios') && 'standalone' in window.navigator && window.navigator['standalone']) {
      this.isInStandaloneMode = true;
    }else{
      this.isInStandaloneMode = false;
    }
    // const isInStandaloneMode = () =>
    //   'standalone' in window.navigator && window.navigator['standalone'];
    // if (this.plt.is('ios') && isInStandaloneMode()) {
    //   console.log('I am a an iOS PWA!');
    // }
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      'username' : [null, Validators.required],
    });


    const scanType: string = this.navParams.get('scanType');
    this.scanType = scanType;

    this.getUsernameFromStorage();


  }

  async getUsernameFromStorage(){
    this.username = await this.storage.get('username');
    console.log("Got USERNAME from storage:", this.username);
  }
 
  async showMessage(message:string){
    const alert = await this.alertController.create({
      message: message,
      buttons: [{text: 'OK', cssClass: 'input-btn-dark'}]
    });
    await alert.present();   
  }

  ngAfterViewInit() {
    this.canvasElement = this.canvas.nativeElement;
    this.canvasContext = this.canvasElement.getContext('2d');
    this.videoElement = this.video.nativeElement;
  }

  async submitPasswordResetByEmail() {

    const alert = await this.alertController.create({
      header: 'E-Mail Adresse eingeben',
      message: 'Bitte geben Sie die bei der Registrierung verwendete Email Adresse ein um Ihr Passwort wiederherzustellen',
      inputs: [
        {
          name: 'email',
          placeholder: 'E-Mail',
          type: 'text'
           //   type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Passwort anfordern',
          handler: resp => {
            if (resp.email) {

              //Fire Password reset request
              let data =
              {
                username: this.username,
                email: resp.email,
                //token: this.scanResult
              }

              this.authService.resetByEmail(data)
                .subscribe(res => {
                  console.log("RESPONSE FROM RESET BY EMAIL:", res);

                  if (res && res.success) {
                    console.log("RESET BY EMAIL SUCESSFULL", res);
                    this.showMessage('Passwort wiederherstellung wurde angefordert!');
                    this.closeModal();
                  }
                });

            } else {
              // invalid reset
              return false;
            }
          }
        },
        {
          text: 'Abbrechen',
          role: 'cancel',
          handler: data => {
            this.closeModal();
          }
        }
      ]
    });
    await alert.present();
  }

  async submitPasswordReset() {

    const alert = await this.alertController.create({
      header: 'Neues Passwort eingeben',
      inputs: [
        {
          name: 'password',
          placeholder: 'Passwort',
          type: 'text'
           //   type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          handler: data => {
            this.closeModal();
          }
        },
        {
          text: 'Passwort Speichern',
          handler: resp => {
            if (resp.password) {

              //Fire Password reset request
              let data =
              {
                username: this.username,
                password: resp.password,
                token: this.scanResult
              }

              this.authService.reset(data)
                .subscribe(res => {
                  console.log("RESPONSE FROM RESET:", res);
                
                  if (res && res.success) {
                    console.log("RESET SUCESSFULLY", res);
                    this.showMessage('Passwort erfolgreich aktualisiert! Sie können sich jetzt einloggen.');
                    this.closeModal();
                    // this.authService.storeToken(res.token);
                  }
                });

            } else {
              // invalid reset
              return false;
            }
          }
        }
      ]
    });
    await alert.present();
  }

  submitActivation() {
    // this.scanResult = "TESTTOKEN123";

    let data =
    {
      username: this.username,
      token: this.scanResult
    }

    this.authService.activate(data)
      .subscribe(res => {
        console.log("RESPONSE FROM ACTIVATE:", res);
      
        if (res && res.success) {
          console.log("ACTIVATED SUCESSFULLY", res);
          this.showMessage('Freischaltung erfolgreich! Sie können sich jetzt einloggen.');
          this.closeModal();
          // this.authService.storeToken(res.token);
        }
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }
 
  // Helper functions
  async showQrToast() {
    const toast = await this.toastCtrl.create({
      message: `Open ${this.scanResult}?`,
      position: 'top',
      buttons: [
        {
          text: 'Open',
          handler: () => {
            window.open(this.scanResult, '_system', 'location=yes');
          }
        }
      ]
    });
    toast.present();
  }
 
  reset() {
    this.scanResult = null;
  }
 
  stopScan() {
    this.scanActive = false;
  }

  async startScan() {
    // Not working on iOS standalone mode!
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' }
    });
   
    this.videoElement.srcObject = stream;
    // Required for Safari
    this.videoElement.setAttribute('playsinline', true);
   
    this.loading = await this.loadingCtrl.create({});
    await this.loading.present();
   
    this.videoElement.play();
    requestAnimationFrame(this.scan.bind(this));
  }
   
  async scan() {
    if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
        this.scanActive = true;
      }
   
      this.canvasElement.height = this.videoElement.videoHeight;
      this.canvasElement.width = this.videoElement.videoWidth;
   
      this.canvasContext.drawImage(
        this.videoElement,
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const imageData = this.canvasContext.getImageData(
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert'
      });
   
      if (code) {
        this.scanActive = false;
        this.scanResult = code.data;

        if(this.scanType == 'activate'){
          this.submitActivation();
        }else{
          this.submitPasswordReset();
        }
        
        // this.showQrToast();
      } else {
        if (this.scanActive) {
          requestAnimationFrame(this.scan.bind(this));
        }
      }
    } else {
      requestAnimationFrame(this.scan.bind(this));
    }
  }

  captureImage() {
    this.fileinput.nativeElement.click();
  }
   
  handleFile(files: FileList) {
    const file = files.item(0);
   
    var img = new Image();
    img.onload = () => {
      this.canvasContext.drawImage(img, 0, 0, this.canvasElement.width, this.canvasElement.height);
      const imageData = this.canvasContext.getImageData(
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert'
      });
   
      if (code) { 
        this.scanResult = code.data;
        if(this.scanType == 'activate'){
          this.submitActivation();
        }else{
          this.submitPasswordReset();
        }
        // this.showQrToast();
      }
    };
    img.src = URL.createObjectURL(file);
  }


}