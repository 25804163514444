import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ContentModel } from '../../content-model';
//import { ContentEntryModel } from '../../content-entry-model';
@Component({
  selector: 'app-list-view-accordion-component',
  templateUrl: './list-view-accordion-component.component.html',
  styleUrls: ['./list-view-accordion-component.component.scss']
})
export class ListViewAccordionComponentComponent implements OnInit {

    @Input()
    contentEntries : any[];

    @Input() mediaViewType: string;
  
    @Input()
    name : string;

    @Input()
    isFirst : boolean;

    @Output()
    contentEntrySelected : EventEmitter<any> = new EventEmitter<any>();

    public isMenuOpen : boolean = false;



    constructor() { }



    ngOnInit() {
      if(this.isFirst){
        this.isMenuOpen = true;
      }
    }


    public toggleAccordion() : void
    {
        this.isMenuOpen = !this.isMenuOpen;
    }

    public broadcastSelectedContentEntry(contentEntry:any){
      console.log("EMIT ID:", contentEntry.id, contentEntry);
      this.contentEntrySelected.emit(contentEntry);
    }

   

    // public broadcastName(name : string) : void
    // {
    //   this.change.emit(name);
    // }

}
