import { GlobalService } from './../../services/global.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContentModel } from '../../content-model';
import { ContentEntryModel } from '../../content-entry-model';

@Component({
  selector: 'app-view-component',
  templateUrl: './view-component.component.html',
  styleUrls: ['./view-component.component.scss']
})
export class ViewComponentComponent implements OnInit {

  @Input() content: ContentModel;


  // @Input() currentScrollTopValue: number;
  @Input() currentScrollStatusClass: string;

  @Output()
  scrollToTopEvent : EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private globalService: GlobalService) { }

  ngOnInit() {
  }

  public switchToListView(){
      this.content.viewType = 'list';
  }

  protected switchToDetailView(){
    this.content.viewType = 'detail';
  }

  public captureContentEntrySelected(contentEntry:ContentEntryModel) : void {
    console.log(`Captured selected content entry by event value: ${contentEntry}`);
    console.log(contentEntry);
    
    this.content.currentContentEntry = contentEntry;
    
    this.switchToDetailView();
    //this.globalService.scrollToTop();
   
    //this.scrollToTopEvent.emit(true); // Send event to page to trigger scroll to top
    

  }

}
