import { Directive, Input, ElementRef, Renderer2, OnInit, Output, EventEmitter } from "@angular/core";
import { DomController } from "@ionic/angular";

@Directive({
  selector: "[myScrollVanish]"
})
export class ScrollVanishDirective implements OnInit {
  @Input("myScrollVanish") scrollArea;

  @Output()
  hiddenByScrollEvent : EventEmitter<boolean> = new EventEmitter<boolean>();

  private hidden: boolean = false;
  private triggerDistance: number = 20;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private domCtrl: DomController
  ) {}

  ngOnInit() {
    this.initStyles();
    //console.log("SCROLL AREA:", this.scrollArea);

    this.scrollArea.ionScroll.subscribe(scrollEvent => {
      let delta = scrollEvent.detail.deltaY;
      //console.log("scrollEvent.detail.currentY", scrollEvent.detail.currentY);
      if (scrollEvent.detail.currentY === 0 && this.hidden) {
        this.show();
      } else if (!this.hidden && delta > this.triggerDistance) {
        this.hide();
      } else if (this.hidden && delta < -this.triggerDistance && scrollEvent.detail.currentY < 30) { //250
        this.show();
      }
    });
  }

  initStyles() {
    //console.log("INIT VANISH DIRECTIVE", this.element.nativeElement);
    this.domCtrl.write(() => {
      this.renderer.setStyle(
        this.element.nativeElement,
        "transition",
        "0.25s linear"
      );
      // this.renderer.setStyle(this.element.nativeElement, "height", "calc(60vh - 44px - 50px)");
      this.renderer.setStyle(this.element.nativeElement, "transform", "scale(1.4)");
    });

    this.hiddenByScrollEvent.emit(false);
  }

  hide() {
    this.domCtrl.write(() => {
      // this.renderer.setStyle(this.element.nativeElement, "height", "75vh");
      this.renderer.setStyle(this.element.nativeElement, "transform", "scale(1)");
      //this.renderer.setStyle(this.element.nativeElement, "min-height", "70vh");
      // this.renderer.setStyle(this.element.nativeElement, "opacity", "1");
      // this.renderer.setStyle(this.element.nativeElement, "padding", "0");
    });

    this.hidden = true;

    this.hiddenByScrollEvent.emit(true);
  }

  show() {
    //console.log("TRIGGER SHOW!");
    this.domCtrl.write(() => {
      // this.renderer.setStyle(this.element.nativeElement, "height", "calc(100vh - 44px - 50px)");
      this.renderer.setStyle(this.element.nativeElement, "transform", "scale(1.5)");
      // this.renderer.removeStyle(this.element.nativeElement, "opacity");
      // this.renderer.removeStyle(this.element.nativeElement, "min-height");
      // this.renderer.removeStyle(this.element.nativeElement, "padding");
    });

    this.hidden = false;

    this.hiddenByScrollEvent.emit(false);
  }
}