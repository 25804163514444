import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutorialGuard } from './guards/tutorial.guard';

const routes: Routes = [
   { path: '', canActivate: [AuthGuard], loadChildren: './tabs/tabs.module#TabsPageModule' },
  // { path: 'login', canActivate: [TutorialGuard], loadChildren: './public/login/login.module#LoginPageModule' }, //with tutorial guard
  { path: 'login', loadChildren: './public/login/login.module#LoginPageModule' },
  {
    path: 'tutorial',
    loadChildren: './public/tutorial/tutorial.module#TutorialPageModule'
  },
  { 
    path: 'members', 
    // canActivate: [AuthGuard, TutorialGuard],
    canActivate: [AuthGuard],
    loadChildren: './members/member-routing.module#MemberRoutingModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
