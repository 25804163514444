import { Component, OnInit, Input } from '@angular/core';
import { ContentModel } from '../../content-model';
@Component({
  selector: 'app-list-view-avatar-component',
  templateUrl: './list-view-avatar-component.component.html',
  styleUrls: ['./list-view-avatar-component.component.scss']
})
export class ListViewAvatarComponentComponent implements OnInit {

  @Input() content: ContentModel;

  constructor() { }

  ngOnInit() {
  }

}
