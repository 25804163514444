import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // public scrollArea:any
  public showById:number;

  constructor() { }




  // public scrollToTop(){
  //   this.scrollArea.scrollToTop(1500);
  // }


}
