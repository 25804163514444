import { from, Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor( private storage: Storage){}

    intercept(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
        return from(this.storage.get('jwt-token'))
              .pipe(
                switchMap(token => {
                    if(token){
                        console.log("TOKEN IN INTERCEPTOR FOUND!", token);

                        let clone: HttpRequest<any>;
                        clone = request.clone({
                            setHeaders: {
                                Accept: `application/json`,
                                'Content-Type': `application/json`,
                                'X-Requested-With': `XMLHttpRequest`,
                                Authorization: `${token}`
                            }
                        });
                          return next.handle(clone);
                    }else{
                        console.log("NO TOKEN IN INTERCEPTOR FOUND!");
                        let clone = request.clone({
                            setHeaders: {
                                Accept: `application/json`,
                                'X-Requested-With': `XMLHttpRequest`,
                                'Content-Type': `application/x-www-form-urlencoded`
                            }
                        });
                        return next.handle(clone);
                    }               
                })
               );
    }
}