import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AlertController, Events } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Startseite',
      url: '/tabs/home',
      icon: 'assets/media/ic_home_grey.svg'
    },
    // {
    //   title: 'Chat',
    //   url: '/tabs/chat',
    //   icon: 'assets/media/ic_chat_grey.svg'
    // },
    {
      title: 'Video-Blog',
      url: '/tabs/gfblog',
      icon: 'assets/media/ic_video_grey.svg'
    },
    {
      title: 'Aktuelles',
      url: '/tabs/uptodate',
      icon: 'assets/media/ic_news_grey.svg'
    },
    {
      title: 'Dokumente',
      url: '/tabs/downloads',
      icon: 'assets/media/ic_downloads.svg'
    },
    {
      title: 'Klinik-Magazin',
      url: '/tabs/pdf', 
      icon: 'assets/media/ic_pdf.svg'
    },
    // {
    //   title: 'Speiseplan',
    //   url: '/tabs/mealplan',
    //   icon: 'assets/media/ic_mealplan_grey.svg'
    // },
    // {
    //   title: 'Ihre Meinung',
    //   url: '/tabs/opinion',
    //   icon: 'assets/media/ic_opinion_grey.svg'
    // },
    // {
    //   title: 'Neu bei Lukas',
    //   url: '/tabs/news',
    //   icon: 'assets/media/ic_new-person_grey.svg'
    // },
    // {
    //   title: 'Wussten Sie schon ?',
    //   url: '/tabs/home',
    //   icon: 'assets/media/ic_quiz_grey.svg'
    // },
    // {
    //   title: 'Soft Skills',
    //   url: '/tabs/softskills',
    //   icon: 'assets/media/ic_group-outline_grey.svg'
    // },
    // {
    //   title: 'Kontakt',
    //   url: '/tabs/home',
    //   icon: 'assets/media/ic_contact_grey.svg'
    // },
    {
      title: 'Datenschutz',
      url: '/tabs/privacy',
      icon: 'assets/media/ic_privacy-protection.svg'
    },
    {
      title: 'Impressum',
      url: '/tabs/imprint', 
      icon: 'assets/media/ic_paragraph.svg'
    },
    {
      title: 'Hilfe',
      url: '/tabs/help', 
      icon: 'assets/media/ic_help.svg'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
    private oneSignal: OneSignal,
    private events: Events,
    private alertCtrl: AlertController //Onesignal debug only
  ) {
    this.initializeApp();
  }

  menuOpened(){
    this.events.publish('menu:opened', '');
    // console.log("OPEN MENU");
    // alert('menu opened');
  }
  menuClosed(){
    this.events.publish('menu:closed', '');
    // console.log("OPEN MENU");
    // alert('menu opened');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.authService.authenticationState.subscribe(state => {
        if (state) {
          this.router.navigate(['tabs', 'home']);
        } else {
          this.router.navigate(['login']);
        }
      });

      if (this.platform.is('cordova')) {
        this.setupPush();
      }
 
    });
  }

  setupPush() {
    this.oneSignal.startInit('4a6bc714-678e-437c-84ae-b0fffa991ee8', '119302339606');
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

    // Notifcation received
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;

      //this.showAlert(title, msg, 'test'); // Activate to debug incoming push messages
    });
 
    // Notification was clicked/opened
    // this.oneSignal.handleNotificationOpened().subscribe(data => {
    //   console.log("Notification opened:", data);
    // });
 
    this.oneSignal.endInit();

    this.getPermission().then((permissions) => {
      if (permissions.permissionStatus == 'undefined') {
          this.promptForPermission().then((userPermission) => {
            if (!userPermission) {
                  this.oneSignal.setSubscription(false);
            }else{
                    this.oneSignal.setSubscription(true);
            }
          });         
      }
    })
  }



   async promptForPermission() {
    return new Promise(async (resolve) => {
      const confirm = await this.alertCtrl.create({
        header: 'Benachrichtigungen',
        message: 'Möchten Sie Push Benachrichtigungen erlauben ?',
        buttons: [
          {
            text: 'Blockieren',
            role: 'cancel',
            handler: () => {
              return resolve(false);
            },
          },
          {
            text: 'Zulassen',
            handler: () => {
              return resolve(true);
            },
          },
        ],
      });

      await confirm.present();
    });
  }

  async getPermission() {
      const permission = await this.oneSignal.getPermissionSubscriptionState();
      if (permission.subscriptionStatus.subscribed && (
        (this.platform.is('android') && permission.permissionStatus['state'] === 1) ||
        (this.platform.is('ios') && permission.permissionStatus.status === 2))
      ) {
        return { permissionStatus: 'subscribed', subscriptionStatus: permission.subscriptionStatus };
      } else if (permission.permissionStatus.hasPrompted &&
        !permission.subscriptionStatus.subscribed && (
          (this.platform.is('android') && permission.permissionStatus['state'] === 1) ||
          (this.platform.is('ios') && permission.permissionStatus.status === 2))
      ) {
        return { permissionStatus: 'undefined', subscriptionStatus: permission.subscriptionStatus };
      } else {
        return { permissionStatus: 'unsubscribed', subscriptionStatus: permission.subscriptionStatus };
      }
    }

  logout() {
    this.authService.logout();
  }

  // FOR PUSH DEBUG ONLY
  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    })
    alert.present();
  }
}
